import Link from "next/link";

import Button from "../components/Button";
import Logo from "../components/Logo";

const Header = () => (
  <>
    <style jsx>{`
      .header {
        border-bottom: var(--border-width) solid var(--border-color);
        column-gap: var(--gutter-width);
        display: grid;
        place-content: center;
        justify-items: center;
        padding: var(--gutter-width);
        row-gap: var(--size-m);
      }
      .logo-narrow {
        cursor: pointer;
        grid-column: 1 / -1;
      }
      .logo-wide {
        display: none;
        cursor: pointer;
        grid-column: 1 / 3;
      }
      .content {
        align-items: center;
        display: grid;
        column-gap: var(--size-s);
        grid-auto-flow: column;
        grid-column: 1 / -1;
        justify-content: center;
        row-gap: var(--size-m);
      }
      .nav {
        align-items: center;
        display: grid;
        column-gap: var(--size-s);
        grid-auto-flow: column;
        justify-content: center;
      }
      .link {
        cursor: pointer;
        font: var(--font-bold);
      }
      .link:hover {
        text-decoration: underline;
      }
      .actions {
        grid-row: 2;
      }
      @media (min-width: 504px) {
        .header {
          grid-template-columns: repeat(4, var(--column-width));
        }
        .actions {
          grid-row: auto;
        }
      }
      @media (min-width: 744px) {
        .header {
          height: var(--size-xxxl);
          grid-auto-flow: column;
          grid-template-columns: repeat(6, var(--column-width));
          padding: 0 var(--gutter-width);
          justify-items: stretch;
        }
        .logo-narrow {
          display: none;
        }
        .logo-wide {
          display: block;
        }
        .content {
          column-gap: var(--gutter-width);
          grid-column: 3 / -1;
          justify-content: right;
        }
        .nav {
          column-gap: var(--gutter-width);
          justify-content: right;
        }
      }
      @media (min-width: 956px) {
        .header {
          grid-template-columns: repeat(8, var(--column-width));
        }
      }
      @media (min-width: 1224px) {
        .header {
          grid-template-columns: repeat(10, var(--column-width));
        }
      }
    `}</style>
    <header className="header">
      <div className="logo-narrow">
        <Link legacyBehavior href="/">
          <a>
            <Logo layout="vertical" size={32} />
          </a>
        </Link>
      </div>
      <div className="logo-wide">
        <Link legacyBehavior href="/">
          <a>
            <Logo layout="horizontal" size={48} />
          </a>
        </Link>
      </div>
      <div className="content">
        <nav className="nav">
          <Link legacyBehavior href="/">
            <a className="link">Home</a>
          </Link>
          <Link legacyBehavior href="/vision">
            <a className="link">Vision</a>
          </Link>
        </nav>
        <div className="actions">
          <Button href="https://confirmsubscription.com/h/t/D6D36B03D15F8946">
            Get early access
          </Button>
        </div>
      </div>
    </header>
  </>
);

export default Header;
